<template>
  <div>
    <IncomesChart />
    <IncomesSummary />
  </div>
</template>

<script>

import IncomesChart from './IncomesChart.vue'
import IncomesSummary from './IncomesSummary.vue'

export default {
  components: {
    IncomesChart,
    IncomesSummary,
  },
}
</script>

<style>

</style>
